<template>
    <input
        type="checkbox"
        v-model="checked"
        :class="[
            'border-gray-300 transition duration-100 ease-in-out rounded shadow-sm focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
            variant !== 'colorless' ? 'text-indigo-500' : ''
        ]"
    />
</template>

<script setup>
const checked = defineModel()
const props = defineProps({
    variant: {
        type: String,
        default: 'default'
    }
})
</script>
